import { render, staticRenderFns } from "./questionDetail.vue?vue&type=template&id=4995b21d&scoped=true&"
import script from "./questionDetail.vue?vue&type=script&lang=js&"
export * from "./questionDetail.vue?vue&type=script&lang=js&"
import style0 from "./questionDetail.vue?vue&type=style&index=0&id=4995b21d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4995b21d",
  null
  
)

export default component.exports